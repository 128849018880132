<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('teams.new')">
        <data-form
          :team="team"
          :loading="loading"
          @submit="submit"
        ></data-form>
      </va-card>
    </div>
  </div>
</template>

<script>
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'teams-new',
  components: {
    DataForm,
  },
  data () {
    return {
      loading: false,
      team: {
        status: 0,
        type: 0,
      },
    }
  },
  methods: {
    routeBuilder () {
      return 'teams'
    },
    async submit (team) {
      this.loading = true

      let response = false
      try {
        response = await this.$http.post(this.routeBuilder(), team)
      } catch (error) {
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'teamsEdit', params: { id: response.data.data.id } })
    },
  },
}
</script>
